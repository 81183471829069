import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
        <h2>Introduction</h2>
        <p>
        Mihretu is a Portland native who’s lived as a first-generation Ethiopian-American in the City of
Roses, apart from a few years in Eugene. He’s worked with people who struggle with anxiety,
depression, trauma, and various adjustment issues, including cultural adjustment issues.
Mihretu’s counseling specialties consist of traditional Cognitive Behavioral Therapy, TraumaFocused Cognitive Behavioral Therapy, Person-Centered Therapy and Christian Counseling for
those who want to incorporate their faith into the counseling sessions. He blends these therapies
together in a way that is catered to your unique needs.
</p>
<p>
Mihretu graduated with a Bachelor of Science in Psychology in 2015 from George Fox
University, then graduated with a Master of Arts in Clinical Mental Health Counseling in 2019
from George Fox University.
</p>
<p>
Mihretu believes that each individual has a unique story and intended identity that transcends
their circumstances, their culture, and their past experiences. He wants to walk alongside you in
this as you together explore and tend to the thought, emotional, and behavioral struggles you are
facing in a space that holds nothing but empathy for you.
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
