import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
        <h2>Introduction</h2>
        <p>
        Are you feeling overwhelmed by persistent thoughts, unhelpful behaviors, or difficult emotions? Maybe what you once felt called to feels so challenging it no longer brings you joy. I am glad you are here. Choosing the ‘right’ counselor can be a challenging task. So let me tell you a bit about myself. 
        </p>
        <p>
        I believe that experiences shape our identities, thoughts, and behaviors. This is why it can be so helpful to have an empathetic, safe space to express yourself and tell your story. You are the expert in your own life and with the help of counseling, we can improve your skills and recognize ineffective patterns. I am rooted in attachment theory, cognitive-behavioral techniques, and mindfulness practices, however, I tailor my approach to best meet the needs of each client. I see children, parents, and adult individuals.
        </p>
        <p>
        Out of the office I enjoy baking, gardening, hiking, camping, and spending time with my family. I would be honored to help you in your journey of healing and change. 
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
