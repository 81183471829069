import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const ThankYouWrap = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (max-height: 780px) and (min-width: 700px) {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  h2 {
    margin-bottom: 20px;
  }

  .message {
    margin-bottom: 20px;
    color: #2f3630;
    text-align: center;
    font-size: 16px;
    line-height: 1.5;
    width: 481px;
    max-width: 100%;
  }

  .button-container {
    padding: 20px;
    background: url("/path-to-your-background-image.jpg") no-repeat center
      center;
    background-size: cover;
    margin: 0 auto;
    display: inline-block; 
  }

  .find-counselor-button {
    background-color: #4caf50; 
    border: none;
    color: white;
    padding: 15px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
  }

  .counselors-wrap {
    padding: 50px 100px;
    background-image: url("/images/get-started/counselors-bg.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 729px;
    @media screen and (max-width: 700px) {
      width: 100%;
      padding: 50px 0;
    }
    .button {
      //position tweak to sit nicely with background image
      position: relative;
      top: 6px;
    }
  }
`;

const ThankYou = () => {
  let navigate = useNavigate();

  const handleClick = () => {
    navigate("/counselors");

    //close popup (less verbose, vanilla approach)
    document.getElementById("popup-close").click();
  };

  return (
    <ThankYouWrap>
      <h2>Thank You</h2>
      <p className="message">
        <b>
          Expect to receive a call by the next business day that aligns with
          your requested available days/time window.
        </b>
        <br />
        <br />
        You are also welcome and invited to view our Counselors page, where you
        can learn more about our providers.
      </p>
      <div className="counselors-wrap">
        <div className="button-container">
          <button onClick={handleClick} className="submit-button">
            Find a Counselor
          </button>
        </div>
      </div>
    </ThankYouWrap>
  );
};

export default ThankYou;
