import React, { useEffect } from "react";
import styled from "styled-components";

const FadeInDiv = styled.div`
  .scroll-fade-in {
    opacity: 0.1;
    transition: opacity ease 1.5s;
  }
`;

const FadeInOnScroll = ({ children }) => {
  useEffect(() => {
    const handleScroll = () => {
      const fadeElements = document.querySelectorAll(".scroll-fade-in");

      fadeElements.forEach((element) => {
        const elementPosition = element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        // Check if element is in viewport
        if (elementPosition < windowHeight) {
          if (element.style.opacity !== 1) {
            let delay = 0;
            if (element.classList.contains("delay-2")) {
              delay = 300;
            }
            if (element.classList.contains("delay-3")) {
              delay = 600;
            }

            setTimeout(() => {
              element.style.opacity = 1;
            }, delay);
          }
        }
      });
    };

    // Initial check
    handleScroll();

    // Add event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return <FadeInDiv>{children}</FadeInDiv>;
};

export default FadeInOnScroll;
