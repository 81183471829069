import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        I know that seeking out and beginning one’s therapy journey requires a great deal of courage. The clients I work with generally arrive facing tremendously challenging life events that often leave them feeling overwhelmed, helpless, bereaved, and even broken. My main priority as a clinician is to provide a safe, compassionate, non-judgmental space where you feel supported and can imagine and realize new possibilities. Your stories, life experiences, strengths and weaknesses, spiritualities, and identities will be witnessed, honored, and respected.
        </p>
        <p>
        
        Ultimately, your goals and a collaborative therapeutic relationship are what form the foundation of any work we do together. Although I prioritize narrative, attachment, somatic and internal family systems (IFS) approaches and believe in the power of self-compassion, mindfulness, creativity, and imagination, I also incorporate aspects of cognitive behavioral therapy (CBT), dialectical behavior therapy (DBT), and solutions-focused and psychodynamic therapies to meet clients’ individual needs.
        </p>
        <p>
        I specialize in working with youth and adults across the lifespan, particularly those whose lives have been impacted by trauma. As an LGBTQIA-identified therapist, I see it as a privilege to support those in the queer community. For individuals who have experienced religious trauma or spiritual abuse, I understand how impactful these types of traumas can be. I also know that individuals have the capacity to grow and flourish as they learn to trust, love, and become their most authentic selves.
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
