import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Servicios Terapeuticos en Español.<br/><br/>Introduction</h2>
        <p>
        I provide bilingual services in Spanish and English (English introduction below).
        </p>

        <p>
        Ay, no, primero el virus de la corona, ahora el Monkeypox. Que no se los olvide la seguridad de los ninos el las escuelas y decidiendo si volver o no al trabajo or trabajar en casa. La desiciones que tiene que hacer para usted o su familia son deficiles y tal vez comiensa sentirse deprimida/o on desesperados. Pero tambien tenemos que trabajar para sobrevivir. Tal vez piensan que estan sola/os y no tienen a nadien con quien hablar y que no estan interesados en su bienestar, Pero eso no es cierto, para aqui estoy yo para ayudarle y guirarle en las etapas mas deficiilies que se le estan afrentando.
        </p>
        <p>
        Creo que todo necesitamos a alguien con quien hablar y alguien que los escuche. Creo en proporcionar un enfoque centrado en la persona en donde sus propias experiencias de vida y creencias son reconocidas y respetadas. Su prioridad es mi prioridad por que usted es la experta/o en su vida. You le guiare y le ayudare a superar cualquier obstaculo que esta enfrentando en su vida, y los enfrentaremos juntos Y tambien hablo espanol por que si se puede y aqui estoy para serviles en que se los ofresca.
        </p>
        <p>
        Tengo mas de 25 anos de experiencia, y yo tambien a pasado por muchas cosas en mi vida. Yo se que es sufrir, y sacrifarse para seguier adelante. Yo a trabajado con gentes y familias de diferentes nacionales y tambien trabajo con adolescents, adultos y con gente mayor. La salud mental es muy importante y mi experienca son con gente que a pasado por trauma, Transtorno de estres postraumatico, lesiones cérébrales traumaticos, abuso physico, emocional, y sexual, el disorden de bipolar, Esquizofrenia, depression, ansiedad, perdidas y mucho mas.
        </p>

        <h2>Therapy Services</h2>
        <p>
        Well, here we are, most of us are recovering from a global pandemic, monkeypox, and now we must worry whether our children will be safe in their schools and whether to return to work or work from home. The decisions we must make for ourselves and our families can be difficult and sometimes that can be very overwhelming. Life can be stressful; I know firsthand what it’s like to suffer and make sacrifices I wasn’t ready for. I too have issues; I would be lying to you if I told you otherwise. Sometimes it can feel as if we have no one to talk to and nobody who cares. Well, that’s not true, I care, and we can face life's issues together. You don’t have to face this alone.
        </p>
        <p>
        I possess over 25 years of experience in working with an array of mental health disorders; anxiety, depression, PTSD, Bipolar Disorder, Schizophrenia, Traumatic Brain Injuries, Human Trafficking victims and survivors of physical, sexual, and emotional abuse as well as grief and loss and have guided people of different nationalities, children, adolescents, young adults as well as with older adults.
        </p>
        <p>
        I believe in providing a person-centered approach in which your own life experiences and beliefs are acknowledged and respected. Your priority is my priority as you are in essence the expert in your life. I will guide you and help you through any obstacles you are facing, and we will face them together.           
        </p>
        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
