import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        My goal is to bring a warm presence, calm demeanor, and intentionality to the therapeutic space. I understand that building connection and rapport are the foundation to all other therapeutic work and therefore I prioritize an environment where you can feel truly seen, heard, and understood without judgement. My purpose as a therapist is to provide that safe place where together we can explore your conscious and unconscious thoughts and gain insight into the parts of your life where you may be having difficulty. I recognize that therapy is a collaborative effort, and that each client is the expert in their own life. Thus, I will work with you to understand your unique life experiences and create a tailored treatment plan so that you can reach healing and wholeness. I will assist you on this journey by helping you understand how past experiences are impacting you, and will help you identify your own strengths, skills, and goals by providing tools that you can use even long after therapy is finished. 
        </p>
        <p>
        I have experience supporting needs related to: children and youth, young adults, families, life transitions, drug and alcohol issues, trauma, attachment challenges, co-dependency, self-esteem, depression, suicidal ideation, grief, and anxiety. 
        </p>
        
        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
