import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        I am a Pacific Northwest native and have lived in the Willamette Valley for over 20 years. I am an Army Combat Veteran with 9 years of service.  I received my bachelor’s degree in Environmental Science with Fisheries and Wildlife Science from Oregon State University. I obtained my master’s degree in Rehabilitation Counseling from Western Oregon University. I am a Certified Trauma Professional with a passion to assist teens (16+) and adults of all ages especially Veterans, with trauma, PTSD, Anxiety, Depression, Military Sexual Trauma, Grief, Stress, and other disorders. My person-centered approaches include Cognitive Behavioral Therapy (CBT), Dialectical Behavioral Therapy (DBT) while being trauma informed. I am dedicated to providing a safe space that allows you to be open and honest without judgment while focusing on hope and healing. I look forward to meeting you and walking with you on your journey to achieving your goals.
        </p>
        
        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
