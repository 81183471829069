import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
        <h2>Experience</h2>
        <p>
        With over 18 years of dedicated service in law enforcement, I have developed a profound understanding of the stressors and psychological impacts unique to first responders. That journey led me to pursue a master’s degree in counseling, equipping me with a unique perspective and tools to support those facing the invisible wounds of service.
        </p>
        <h2>Approach</h2>
        <p>
        A safe, empathetic, and confidential space is fundamental for healing and growth. I am committed to creating an environment where every individual can feel understood and is able to navigate their thoughts and emotions freely.
        </p>
        <h2>Specialization</h2>
        <p>
        Specializing in addressing the challenges of depression, anxiety, trauma, and PTSD, I employ integrative evidence-based techniques in my therapeutic approach. This includes Cognitive Processing Therapy (CPT), Cognitive Behavioral Therapy (CBT), and Motivational Interviewing (MI), all tailored to meet the unique needs of each client.
        </p>
        <h2>Summary</h2>
        <p>
        As someone who has walked the front lines, I bring a personal understanding and a trauma-informed perspective to my counseling practice, striving to guide each person towards resilience and well-being, with an unwavering commitment to maintaining their privacy and trust.
        </p>
        
        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
