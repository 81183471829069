import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        As a licensed marriage and family therapist (LMFT) and clinical supervisor, my primary goal is to support clinicians by fostering both professional and personal growth. I am credentialed to provide licensure supervision for LPC and LMFT associates in the state of Oregon and am on the OBLPCT Supervisor Registry as well as an AAMFT-Approved Supervisor. In therapy, I work with teens, and adults of all ages. I welcome members of the LGBTQIA+ community and provide affirming care for folks on the gender spectrum and/or amid gender transition. I specialize in treating anxiety disorders, depression, trauma, life transition issues, and relationship concerns (family, partners, social) and have specific training in DBT, CBT, and post-modern systemic treatment modalities. I graduated from the Couples and Family Therapy Program at the University of Oregon (2009) and will often bring systems, identity intersections, and relationships into our conversations.
        </p>
        
        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
