import React, { useState } from "react";
import styled from "styled-components";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import FadeInOnScroll from "../components/FadeInOnScroll";
import CallToAction from "../components/CallToAction";
import IntroBand from "../components/IntroBand";
import StaffData from "../data/placeholder-staff-data.json";
import BioPopup from "../components/BioPopup";
import BioPerson from "../components/BioPerson";

const Hero = styled.div`
  nav {
    z-index: 3;
    position: relative;
  }

  #hero {
    padding: 130px 30px 120px;
    position: relative;
  }

  @media screen and (max-width: 970px) {
    #hero {
      padding: 50px 30px 0;
      margin-bottom: -20px;
      position: relative;
    }
  }

  #hero #hero-graphic {
    position: absolute;
    right: 16px;
    bottom: 30px;
    width: 731.274px;
    transform: rotate(-13.667deg);
  }

  @media screen and (max-width: 1200px) {
    #hero #hero-graphic {
      width: 500px;
      right: 53px;
    }
  }

  @media screen and (max-width: 970px) {
    #hero #hero-graphic {
      position: relative;
      right: unset;
      bottom: 37px;
      max-width: 600px;
      width: 90%;
      display: block;
      margin: 50px auto 0;
    }
  }

  #hero .wrap {
    width: 1184px;
    margin: auto;
    position: relative;
    z-index: 2;
  }

  #hero .inner-wrap {
    flex-direction: column;
    display: flex;
    width: 474px;
  }

  @media screen and (max-width: 970px) {
    #hero .inner-wrap {
      align-items: center;
      text-align: center;
      margin: 0 auto 20px;
    }
  }

  #hero .inner-wrap p {
    margin: 30px 0;
  }

  @media screen and (max-width: 1050px) {
    #hero .inner-wrap p {
      width: 400px;
      max-width: 100%;
    }
  }
`;

const ListingSection = styled.section`
  padding-top: 60px;
`;

const CounselorsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
`;

const ProfileCard = styled.div`
  width: 300px;
  text-align: center;
  margin: 30px 40px;

  @media screen and (max-width: 850px) {
    margin: 10px 20px;
  }

  //hover state
  &.listing-card > div {
    cursor: pointer;
  }
  &.listing-card > div:hover {
    scale: 1.02;
  }
  &.listing-card > div img:hover {
    scale: 1.05;
    filter: grayscale(0);
  }
`;

const ProfileImageWrap = styled.div`
  width: 300px;
  height: 300px;
  border-radius: 10px;
  transition: scale 0.3s ease;
  overflow: hidden;
`;

const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  transition: filter 0.3s ease, scale 0.3s ease;
  scale: 1;
  object-fit: cover;
  object-position: 0 ${(props) => (props.profilePosition ? `${props.profilePosition}` : '0')};
`;

const Name = styled.div`
  font-size: 22px;
  line-height: 1.27;
  font-weight: bold;
  margin-top: 10px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 300;
  line-height: 1.27;
`;

const BioContent = styled.div`
  display: flex;
  align-items: flex-start;
  max-height: 1021px;

  @media screen and (max-height: 1100px) {
    max-height: 80vh;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    max-height: unset;
    /* overflow: auto; */
  }
`;

const BioRight = styled.div`
  max-width: 744px;
  padding-top: 40px;
  position: relative;

  > div {
    padding: 0 20px;
    overflow: auto;
    height: 787px;
    padding-bottom: 100px;

    @media screen and (max-height: 1100px) {
      max-height: 65vh;
    }
    @media screen and (max-width: 800px) {
      height: unset;
      overflow: unset;
    }
  }
  .fade-block {
    position: absolute;
    pointer-events: none;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  h2 {
    color: black;
    margin: 40px 0 20px;
    font-size: 18px;
    font-weight: 700;
  }
  h2:first-of-type {
    margin-top: 0;
  }

  p,
  li {
    font-size: 18px;
    line-height: 1.4;
    margin: 20px 0;
  }
  li {
    margin-left: 30px;
    list-style: disc;
  }
`;

function Staff() {
  //popup
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedStaffMember, setSelectedStaffMember] = useState(false);
  const handleClose = () => {
    setIsPopupVisible(false);
  };

  return (
    <FadeInOnScroll>
      <main>
        {isPopupVisible && (
          <BioPopup onClose={handleClose}>{selectedStaffMember}</BioPopup>
        )}
        <Hero className="green-bg">
          <Nav />

          <section id="hero">
            <div className="wrap">
              <div className="inner-wrap">
                <h1>Our Staff</h1>
                <p>
                  We are here to support you and your counselor. Feel free to call us or use the contact form.<br/><br/>To get to know us better, please scroll down, and click on our images below. 
                </p>
              </div>
            </div>
            <img
              id="hero-graphic"
              className="scroll-fade-in"
              src="images/staff/sakura-branch.png"
              alt="sakura branch"
            />
          </section>
        </Hero>
        <IntroBand>
          "Healing comes when we choose to walk away from darkness and move
          towards a brighter light."
        </IntroBand>
        <ListingSection>
          <CounselorsSection>
            {StaffData.map((counselor, index) => {
              const CardContent = () => (
                <>
                  <ProfileImageWrap>
                    <ProfileImage
                      src={`/images/staff/profile-placeholders/${counselor.profile}`}
                      alt={counselor.name}
                      profilePosition={counselor.profilePosition}
                    />
                  </ProfileImageWrap>
                  <Name>{counselor.name}</Name>
                  <Title>{counselor.title}</Title>
                </>
              );

              const PopupContent = () => (
                <BioContent>
                  <ProfileCard
                    key={index}
                    onClick={() => setIsPopupVisible(true)}
                  >
                    <CardContent />
                  </ProfileCard>
                  <BioRight>
                    <BioPerson  personName={counselor["bio-staff"]}/>
                    <div className="fade-block"></div>
                  </BioRight>
                </BioContent>
              );

              const profileCard = (
                <ProfileCard
                  className={"listing-card"}
                  key={index}
                  onClick={() => {
                    setSelectedStaffMember(PopupContent);
                    setIsPopupVisible(true);
                  }}
                >
                  <CardContent />
                </ProfileCard>
              );

              return profileCard;
            })}
          </CounselorsSection>
          <div className="gap"></div>
        </ListingSection>
        <CallToAction />
      </main>
      <Footer />
    </FadeInOnScroll>
  );
}

export default Staff;
