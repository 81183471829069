import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        ZaDora Williams a.k.a Sunflower Z is a proud Black and Queer Goddess, mompreneur, licensed clinical and holistic social worker, energy healer, Holy Fire Reiki, and Ra Sekhi Kemetic Reiki spiritual practitioner. She's a former mental health therapist (10 years) and the founder of Sankofa Center for Healing, LLC which continues to be a place of healing where she provides mental health consultations, parenting workshops, coaching, and organizational healing that couples mental health awareness and wellness with Anti-bias and Anti-Blackness training/workshops for organizations that desire to heal from racism and bias while creating mentally healthy work environments for people to thrive in. She is also the founder of Sunflower Z’s Healing Garden which is a place to relax, release, and raise your vibrations offering energy healing and spiritual care services that include healing rituals, spiritual coaching, and tools for healing energetically.
        </p>
        <p>
        Like Harriet, ZaDora is a Liberator who enjoys collaborating with other holistic healers to cultivate collective healing events to liberate the mind, body, and spirits of those within the Black and Brown communities and occasionally individuals of other racial backgrounds who feel called to work with her and are spiritually aligned.
        </p>
        <p>
        She is passionate about creating systemic changes in organizations and within the social work profession. She enjoys having the opportunity to support social workers on their licensure journey to become the best clinician they can be. She guides social workers on a journey that will encourage them to be themselves, think critically, and provide tools for understanding mental health challenges and trauma through cultural, clinical, and holistic lenses. Her goal is to support social workers as they become culturally responsive, anti-bias, and competent clinicians who understand the importance of talk therapy and somatic approaches. She is warm, down to earth, engaging, authentic, and direct and will champion social workers to practice self-care as they grow professionally.
        </p>
        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
