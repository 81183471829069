import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        I received my Bachelor's in Social Work and Master's in Social Work from George Fox University. I am currently under the supervision of Cindy Nesiba as I work towards becoming a Licensed Clinical Social Worker.
        </p>
        <p>
        I work primarily with children and teens struggling with a variety of mental health and behavioral concerns looking to understand the impact of their experiences. I approach care from attachment and family systems theory lenses, primarily utilizing motivational interviewing, psychoeducation, cognitive behavioral therapy, play therapy, and include family in treatment to best support the youth. I value working alongside parents in gaining knowledge and skills to cultivate growth with their child. While I understand it is not always the child's decision to engage in therapy, I work to build relationships with my clients to provide them a safe space to express their needs. It is important to me that LGBTQ+ and other marginalized populations feel safe and advocated for in their care.
        </p>
  
        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
