import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        Joe is a compassionate individual who thrives in an environment where he can utilize his listening skills and relatable personality to provide a safe and caring place for others. Joe believes empathy, compassion, and reasonable humor are central to creating an effective relationship that helps clients to make meaningful changes over time. Joe received his Bachelor of Arts degree in Psychology from Eastern Illinois University and his Master of Arts from Pacific University.
        </p>
        <p>
        Joe typically sees clients struggling with Anxiety-Related Disorders, Attention-Deficit Hyperactivity Disorder (ADHD), Depression, and Obsessive-Compulsive Disorder (OCD). Joe’s primary training focused on Cognitive Behavioral Therapy (CBT), but he also incorporates other evidence-based modalities, such as Mindfulness-Based Cognitive Therapy (MBCT), Acceptance and Commitment Therapy (ACT), Solution-Focused Brief Therapy, Positive Psychology, and Dialectical Behavior Therapy (DBT).
        </p>
        <p>
        Joe's most significant contribution to Oregon Directions is that he concentrates on the treatment of OCD-spectrum and anxiety-related disorders using Exposure Response Prevention. As such, he is enthusiastically committed to helping clients achieve freedom from the restraints of fear and obtain a better quality of life.
        </p>
        <p>
        Joe has been a Registered Behavioral Technician for three years, providing individualized Applied Behavioral Analysis services for children and adults with Autism and related disabilities, to help decrease challenging behaviors and increase adaptable behaviors. This background provides him with experience interacting with individuals on the spectrum and motivates him to meet the needs of this population from a counseling perspective. Joe firmly supports Dr. Stephen Shore's quote "If you've met one individual with autism, you've met one individual with autism." Because no two individuals with this disorder have the same needs.
        </p>
        <p>
        “Don't be afraid of your fears. They're not there to scare you. They're there to let you know that something is worth it.” ― C. JoyBell
        </p>
        <p>
        “A failure is not a mistake, it may simply be the best one can do under the circumstances. The real mistake is to stop trying.” ― B. F. Skinner
        </p>
       
        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
