// SidebarFilter.jsx
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ButtonPlain from "./ButtonPlain";
import BlurOverlay from "./BlurOverlay";

const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 485px;
  height: 100%;
  background: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  z-index: 100;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  &.open {
    transform: translateX(0);
  }
`;

const CloseButton = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const FilterTitle = styled.div`
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.27;
  padding: 20px;
`;

const Underline = styled.div`
  border-bottom: 1px solid black;
  width: calc(100% - 10px);
  margin: auto;
`;

const FilterOptions = styled.div`
  padding: 20px;
  height: calc(100% - 155px);
  overflow: auto;
`;

const FilterName = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
  padding-bottom: 10px;
  text-transform: capitalize;
`;
const FilterGroup = styled.div`
  padding-bottom: 15px;
`;

const CheckboxContainer = styled.label`
  display: block;
  margin: 3px 0;
  padding: 3px 0;
  text-transform: capitalize;
  label {
    cursor: pointer;
  }
`;

const Checkbox = styled.input`
  margin-right: 10px;
  width: 14px;
  height: 14px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
  background: white;
  /* use this if filter options get too long and list under the buttons */
  box-shadow: 0 -6px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 400px) {
    flex-direction: column;
    align-items: center;
    height: 160px;
  }
`;

const SidebarFilter = ({
  isOpen,
  onClose,
  counselorsData,
  filters,
  setFilters,
  filterNames,
  defaultEmptyFilters,
}) => {

  const [selectedFilters, setSelectedFilters] = useState({
    ...filters,
  });

  // Handler for checkbox change
  const handleCheckboxChange = (filterName, option, isChecked) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = isChecked
        ? [...prevFilters[filterName], option]
        : prevFilters[filterName].filter((value) => value !== option);

      return {
        ...prevFilters,
        [filterName]: updatedFilters,
      };
    });
  };

  // Function to get unique filter values
  const getUniqueFilterValues = (data, filter) => {
    const allValues = data.map((item) => item[filter]).flat(); // flat in case of arrays
    return [...new Set(allValues)];
  };

  // Object to hold the unique values for each filter
  const filterValues = filterNames.reduce((acc, filter) => {
    acc[filter] = getUniqueFilterValues(counselorsData, filter);
    return acc;
  }, {});

  // Render function for each filter group
  const renderFilterGroup = (i, filterName, options) => {
    const name = filterName.replaceAll("_", " ");

    return (
      <FilterGroup key={i}>
        <FilterName>{name}</FilterName>
        {options.map((option, index) => (
          <CheckboxContainer key={option}>
            <Checkbox
              type="checkbox"
              id={`${filterName}${index}`}
              checked={selectedFilters[filterName].includes(option)}
              onChange={(e) =>
                handleCheckboxChange(filterName, option, e.target.checked)
              }
            />
            <label htmlFor={`${filterName}${index}`}>{option}</label>
          </CheckboxContainer>
        ))}
      </FilterGroup>
    );
  };

  const applyFilters = () => {
    setFilters(selectedFilters);
    onClose();
  };

  useEffect(() => {
    setSelectedFilters({ ...filters });
  }, [filters]);

  return (
    <>
      {isOpen && <BlurOverlay onClick={onClose} />}
      <SidebarContainer className={isOpen ? "open" : ""}>
        <CloseButton src="/images/icons/x.svg" alt="Close" onClick={onClose} />
        <FilterTitle>All Filters</FilterTitle>
        <Underline></Underline>
        <FilterOptions>
          {filterNames.map((filterName, i) =>
            renderFilterGroup(i, filterName, filterValues[filterName])
          )}
        </FilterOptions>
        <ButtonContainer>
          <ButtonPlain
            onClick={() => {
              setFilters(defaultEmptyFilters)
              onClose();
            }}>
            Clear all
          </ButtonPlain>

          <ButtonPlain dark onClick={applyFilters}>
            Apply filters
          </ButtonPlain>
        </ButtonContainer>
      </SidebarContainer>
    </>
  );
};

export default SidebarFilter;
