import React, { useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { ProgressProvider, useProgress } from "../contexts/ProgressContext";
import BlurOverlay from "./BlurOverlayGetStarted";
import ProgressBar from "./ProgressBar";
import BackButton from "./BackButton";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  background: white;
  padding: 20px;
  padding-top: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 1200px;
  width: 90%;
  animation: ${fadeIn} 0.3s ease-in-out forwards;
  @media screen and (max-width: 700px) {
    padding-top: 70px;
  }

  @media screen and (max-width: 700px) {
    width: 100%;
    height: 100%;
  }

  button {
    font-weight: bold;
  }
`;

const MobileHeaderFade = styled.div`
  position: absolute;
  pointer-events: none;
  z-index: 1;
  top: 80px;
  left: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(to top, rgba(255, 255, 255, 0), white);
  @media screen and (min-width: 700px) {
    display: none;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 850px;

  @media screen and (max-height: 960px) and (min-width: 700px) {
    min-height: calc(100vh - 200px);
  }
  
  @media screen and (max-width: 700px) {
    display: block;
    padding-top: 50px;
    height: 100%;
    min-height: unset;
    overflow: auto;
  }

  h2 {
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    color: black;
  }
`;

const BarWrap = styled.div`
  max-width: calc(100% - 100px);
  margin: 0 auto;
  position: relative;
  z-index: 2;
  top: 30px;
  @media screen and (max-width: 700px) {
    max-width: calc(100% - 18px);
    top: 0;
  }
`;

const CloseButton = styled.div`
  position: absolute; 
  top: 20px; 
  right: 40px; 
  z-index: 1;
  border: none;
  background: none;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }

  @media screen and (max-width: 700px) {
    position: fixed;
    top: 20px;
    right: 20px;
  }
`;

const BackWrap = styled.div`
  position: absolute;
  left: 70px;
  top: 112px;
  @media screen and (max-width: 700px) {
    top: 20px;
    left: 40px;
  }
`;

const GetStartedPopup = ({ onClose }) => {
  const { percent, content, history, prevStep } = useProgress();
  const contentRef = useRef();

  const handleBackClicked = () => {
    prevStep();
  };

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [content]);

  // Function to stop the click event from bubbling up
  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <BlurOverlay onClick={onClose}>
        <Container onClick={handleContentClick}>
          <CloseButton onClick={onClose} id="popup-close">
            <img src="/images/icons/x.svg" alt="Close" />
          </CloseButton>
          <MobileHeaderFade></MobileHeaderFade>
          {history.length > 1 && (
            <BackWrap>
              <BackButton onClick={handleBackClicked} />
            </BackWrap>
          )}
          <BarWrap>
            <ProgressBar percent={percent} />
          </BarWrap>
          <Content ref={contentRef}>{content}</Content>
        </Container>
      </BlurOverlay>
    </>
  );
};

const ProvidedWrapedGetStartedPopup = ({ onClose }) => {
  return (
    <ProgressProvider>
      <GetStartedPopup onClose={onClose} />
    </ProgressProvider>
  );
};

export default ProvidedWrapedGetStartedPopup;
