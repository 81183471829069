import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
        <h2>Introduction</h2>
        <p>
        I believe the most valuable therapeutic relationships are those that honor and validate your past and present experiences, with the intention of identifying and navigating obstacles to healing and growth. I approach our work together with respect, empathy, curiosity, and authenticity, so you have a safe supportive place to explore: patterns of thought, feeling, physical sensation, and behavior; your relationship with yourself and with the important people in your life; and the valued directions in which you would like to travel. At times, insights gained in sessions can be made more actionable through new skills, and our focus may shift to their introduction and practice.     </p>
    <p>
    My approach is integrative, in this sense, as I draw from approaches that emphasize the insights born out of exploration, as well as approaches that focus on developing new ways to navigate existing challenges. I prioritize flexibility and responsiveness in my work with you, and all choices regarding our work together are done in collaboration. Areas of focus include anxiety, depression, self-esteem, life-transitions, and communication.         </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
