import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        Are you struggling with negative or difficult emotions? Have your childhood struggles or a trauma taken on a life of their own? Are you feeling “stuck” and want to be more in control of your life? Allow me to partner with you to teach you useful tools to help process, refocus, redirect, and enhance the quality of your life.
        </p>
        <p>
        Seeking therapy is not always an easy choice. Congratulations on making a choice to improve your life. This is the first and most important step in achieving your goal of becoming a happier, healthier you. I consider it a privilege to be given the opportunity to work collaboratively to help my clients begin their healing process. I believe in the power of healing through a therapeutic journey. 
        </p>
        <p>
        As a daughter to immigrant parents, I had the privilege of learning about diversity first-hand and gained a multicultural perspective. I speak Spanish and would love to extend the healing to the Latinx community. I also offer faith-based counseling services if you would like to bring your spiritual beliefs into treatment. I graduated from Hope International University with my Masters in Marriage and Family Therapy. I am passionate about and have experience working with children and families with depression, anxiety, grief/loss, crisis, ADHD, and trauma. Allow me the opportunity to work with you on meeting your goals.
        </p>
        <h2>
        Servicios Terapeuticos en Español.
        </h2>
        <p>
        ¿Estás luchando con emociones negativas o difíciles? ¿Tus luchas infantiles o un trauma han cobrado vida propia? ¿Te sientes “atascado” y quieres tener más control de tu vida? Permítame asociarme con usted para enseñarle herramientas útiles para ayudarlo a procesar, reenfocar, redirigir y mejorar la calidad de su vida.
        </p>
        <p>
        Buscar terapia no siempre es una decisión fácil. Felicidades por tomar una decisión para mejorar su vida. Este es el primer paso y el más importante para lograr su objetivo de convertirse en una persona más feliz y saludable. Considero que es un privilegio tener la oportunidad de trabajar en colaboración para ayudar a mis clientes a comenzar su proceso de curación. Creo en el poder de curar a través de un viaje terapéutico.
        </p>
        <p>
        Como hija de padres inmigrantes, tuve el privilegio de aprender sobre la diversidad de primera mano y obtuve una perspectiva multicultural. Hablo español y me encantaría extender la sanación a la comunidad Hispana. También ofrezco servicios de terapia basados en la fe si desea llevar sus creencias espirituales al tratamiento. Me gradué de la Universidad Internacional de Hope con mi Maestría en Terapia Matrimonial y Familiar. Me apasiona y tengo experiencia trabajando con niños y familias con depresión, ansiedad, duelo/pérdida, crisis, TDAH y trauma. Permítame la oportunidad de trabajar con usted para alcanzar sus metas. 
        </p>
        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
