import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
        <h2>Introduction</h2>
        <p>
        Andie received a Masters of Arts in Art Therapy Counseling from Marylhurst University in 2011 and has been a Licensed Professional Counselor since 2017. Her background includes supporting youth, adults, families and experiencing trauma, domestic violence, navigating different systems including child welfare, family conflict and housing instability. Andie has experience supporting clients from diverse backgrounds different from her own and the LGBTQA+ community.
        </p>
        <p>
        Andie hopes to continue supporting teens and adults to explore and develop skills for emotional regulation, stress management, healthy relationships, clarification of past events, and self-empowerment. Andie continues to do her personal work to practice cultural humility and recognize systemic traumas that are impacting communities of color. Andie provides gender-affirming care. 
        </p>
        <p>
        Areas of expertise include history of witnessing or experiencing domestic violence, aging out of foster care, reconciling grief and loss stemming from trauma and or disrupted relationships, and parenting when impacted by trauma.
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
