import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        I believe that self-awareness and self-compassion are the foundations to finding more emotional peace. Counseling is a partnership between therapist and client where we will explore conscious and unconscious patterns to find more emotional balance. My approach to counseling involves helping you identify your strengths and develop a deeper understanding of yourself. My work is person-centered and based in trauma-informed theories such as cognitive behavioral therapy, dialectical behavior therapy, internal family systems, positive psychology, solution-focused, and strengths-based. I have a special interest in working with individuals who are navigating anxiety, depression, body dysmorphia, ADHD, chronic pain, and trauma. Together we will work to explore and understand your patterns, barriers, and goals and move forward towards healing and growth.        </p>
        <p>
        As a woman of color and daughter of Middle Eastern immigrants, I embrace a multicultural perspective and believe that the diversity around us adds a broader understanding of the world and of ourselves. I understand the challenge of learning to balance different sets of cultural norms, and I empathize with the experience of being “othered” in our society. As your counselor, I will hold space for your personal journey and offer a safe space for us to move forward together.        
        </p>
        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
