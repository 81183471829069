import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ButtonPlain from "./ButtonPlain";
import RadioButton from "./RadioButton";
import BlurOverlay from "./BlurOverlay";

const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 300px; // Adjust the width as needed
  height: 100%;
  background: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  z-index: 101; // Ensure this is above other content but below modals if any
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  &.open {
    transform: translateX(0);
  }
`;

const SortTitle = styled.div`
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.27;
  padding: 20px;
`;

const Underline = styled.div`
  border-bottom: 1px solid black;
  width: calc(100% - 10px);
  margin: auto;
`;

const CloseButton = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const SortOptions = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  button {
    margin-top: 20px;
  }
`;

const SidebarSort = ({ isOpen, onClose, onSort, onClearSort }) => {
  const [sortValue, setSortValue] = useState('');

  useEffect(() => {
    if(sortValue) onSort(sortValue);
  }, [sortValue]);

  const clear = () => {
    setSortValue('');
    onClearSort();
  }

  return (
    <>
      {isOpen && <BlurOverlay onClick={onClose} />}
    
    <SidebarContainer className={isOpen ? "open" : ""}>
      <CloseButton src="/images/icons/x.svg" alt="Close" onClick={onClose} />
      <SortTitle>Sort</SortTitle>
      <Underline />
      <SortOptions>
        <RadioButton name="sort-by" value="asc" label="Sort A-Z" currentValue={sortValue} setter={setSortValue}/>
        <RadioButton name="sort-by" value="desc" label="Sort Z-A" currentValue={sortValue} setter={setSortValue}/>
        <ButtonPlain onClick={clear}>Clear</ButtonPlain>
      </SortOptions>
    </SidebarContainer>
    </>

  );
};

export default SidebarSort;
