import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        Are you overwhelmed by negative self-talk? Maybe you feel that no matter how hard you try, you somehow seem to miss the mark you set for yourself. Are you struggling with a sense of overwhelming anxiety, and your usual methods of coping are just not working anymore? Perhaps you've been hesitant to seek professional advice because you had a bad experience with a therapist, or you simply dislike labels. I am here to lend a hand. I strive to practice in a way that is most comfortable for my clients. For some, a diagnosis feels like validation; for others, it's a prison. I am happy to provide the service that best suits your needs.
        </p>
        <p>
        I believe that good therapy is collaborative and non-authoritative. As a queer individual from the south, I am confident in my ability to provide meaningful services and support in LGBTQ matters. I also specialize in neurodivergent issues. My approach draws from mindfulness, meditation, somatic therapy, spiritual guidance, and solution-focused therapy.
        </p>
        <p>
        I understand that reaching out can be daunting. I am here to be a resource for you on your journey. Together, we will explore your inner truth and discover your authentic, wonderful self. Thank you for taking the first step to reach out, and I look forward to working with you.
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
