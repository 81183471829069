import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        Greetings! I am a dedicated bilingual clinical mental health counselor deeply committed to fostering healing and empowerment among diverse populations. With a compassionate and culturally sensitive approach, I specialize in working with children, teens, families, and adults facing a spectrum of challenges including trauma, anxiety, depression, ADHD, PTSD, and life changes such as divorce, loss, children’s developmental stages, life transitions, cultural and immigration challenges when moving to a new country, marriage, break ups, etc.
        </p>
        <p>
        I hold a master’s degree in clinical Mental Health Counseling from George Fox University. My previous education includes a bachelor’s degree in medicine from Universitat Internacional de Catalunya.
        </p>
        <h2>
        Professional Philosophy:
        </h2>
        <p>
        I believe our identity, thoughts and behaviors are shaped by life experiences and key relationships. I strongly rely on Dialectical Behavioral Therapy to support clients in their pursuit of change and their overall healthy functionality by using mindfulness, distress tolerance, emotion regulation and interpersonal effectiveness skills. Additionally, I operate from a family systems perspective and believe that something impacting one person in a family can affect other family members in a variety of different ways. For this reason, I may use a combination of individual and family therapies to provide additional support, increase connections, and improve relationships. I also incorporate play therapy into session to allow youth the space for self-expression, time to process experiences, and opportunity to use play as their language (which can be a powerful form of communication). 
        </p>
        <h2>
        Areas of Expertise:
        </h2>
        <ol>
          <li>
            <b>Trauma-Informed Care:</b>I employ evidence-based interventions to address trauma and its impact on mental health. Through trauma-focused therapy, I help clients navigate distressing experiences, build coping skills, and reclaim a sense of safety and control in their lives.
          </li>
          <li>
            <b>Anxiety and PTSD:</b> I offer specialized support for individuals grappling with anxiety disorders and post-traumatic stress disorder (PTSD). By integrating cognitive-behavioral techniques and mindfulness practices, I assist clients in managing symptoms, reducing stress, and cultivating resilience in the face of adversity.
          </li>
          <li>
            <b>Grief and loss:</b> I provide compassionate guidance to individuals and families navigating the complex journey of grief and loss. Through a person-centered approach, I honor each individual's unique grieving process and facilitate healthy coping strategies to foster healing and adaptation.
          </li>
          <li>
            <b>Racial and Immigration Trauma: </b>Recognizing the profound impact of systemic oppression and migration experiences, I address racial and immigration trauma with sensitivity and cultural humility. I strive to validate clients' lived experiences, promote cultural pride, and facilitate resilience-building within marginalized communities.
          </li>
          <li>
            <b>Life Adjustments and Transitions: </b>  I support individuals and families facing significant life transitions, including divorce, relocation, and family changes. By offering practical strategies and emotional support, I empower clients to navigate transitions with grace and resilience, fostering opportunities for personal growth and renewal.
          </li>
        </ol>
        <h2>
          Bilingual and Multicultural Approach:
          </h2>
          <p>
          Fluent in both English and Spanish, I embrace diversity and celebrate the richness of multicultural identities. I offer therapy services in both languages, ensuring accessibility and inclusivity for clients from diverse linguistic and cultural backgrounds.
          </p>
          <h2>
          Collaborative and Holistic Care:
          </h2>
          <p>
          I collaborate closely with clients to develop personalized treatment plans tailored to their unique strengths, needs, and goals. I believe in a holistic approach to mental health that integrates psychoeducation, skill-building, and community resources to promote long-term well-being.
          </p>
          <p>
          Thank you for considering me as a partner in your healing journey.
          </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
