import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        I am passionate about providing a warm, welcoming and safe therapeutic space. If you’re struggling with difficult feelings from trauma, grief, depression and anxiety, or having issues communicating in your relationships, it would be a privilege to work with you. My goal is to help you grow in your understanding of yourself and support you in making the changes necessary for improving your mental health.
        </p>
        <p>
        My therapeutic approach is always rooted in unconditional positive regard and empathy. With strategies tailored to each individual’s needs, I utilize relational, cognitive-behavioral therapy, cross-cultural, existential, solution-focused, and systems theory methods. Also, I draw on research from the field of neuroscience to help you understand your own thinking. I have experience working in multicultural, emergency services, and educational settings, which has shaped my ability to think creatively and help others develop tools for approaching old problems in new ways.
        </p>
        <p>
        I welcome the opportunity to work with: individuals (16 years+), couples, parents and families, all in various stages of life, and from diverse backgrounds. If you are seeking a compassionate therapist with the depth and breadth of professional and life experience, it is my hope you reach out. I look forward to meeting you!
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
