import styled, { keyframes } from "styled-components";

const BackArrowText = styled.div`
  color: #5e8a64;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  text-decoration: none;
  user-select: none;

  &::after {
    content: url("../images/arrow.svg");
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    transition: left ease 0.4s;
  }

  &:hover {
    opacity: 0.8;
    &::after {
      left: -10px;
    }
  }
  &:active {
    opacity: 0.5;
  }
`;

const BackButton = ({ onClick }) => {

  // Function to stop the click event from bubbling up
  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <BackArrowText onClick={onClick}>
      <b>Back</b>
    </BackArrowText>
  );
};

export default BackButton;
