import React from "react";
import styled, { keyframes } from "styled-components";
import BlurOverlay from "./BlurOverlay";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  background: white;
  padding: 20px;
  padding-top: 40px; /* Make room for the absolute close button */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 1200px;
  width: 90%;
  animation: ${fadeIn} 0.3s ease-in-out forwards;

  @media screen and (max-width: 800px) {
    max-height: 80vh;
    overflow: auto;
    padding-bottom: 50px;
  }
`;

const CloseButton = styled.div`
  position: absolute; /* Fixed positioning relative to the viewport */
  top: 20px; /* Adjust the value to match your design */
  right: 40px; /* Adjust the value to match your design */
  z-index: 1;
  border: none;
  background: none;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }

  @media screen and (max-width: 800px) {
    position: fixed;
    top: 60px;
  }
`;

const BioPopup = ({ children, onClose }) => {
  // Function to stop the click event from bubbling up
  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <BlurOverlay onClick={onClose}>
        {/* Add onClick handler to Container to stop event propagation */}
        <Container onClick={handleContentClick}>
          <CloseButton onClick={onClose}>
            <img src="/images/icons/x.svg" alt="Close" />
          </CloseButton>
          {children}
        </Container>
      </BlurOverlay>
    </>
  );
};

export default BioPopup;
