import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        Just as we need air to breathe and survive, we need connection and compassion to heal and thrive. I believe your mental health is just as important as other aspects of your health and dealing with feelings like anger, shame, ambivalence, and loneliness isn’t something you should have to do alone. At times we need to talk and reflect on our issues, other times we need to make direct changes to our circumstances and lifestyles. In either case, I am happily honored to be your counselor and meet your needs while dealing with the uncertainty, depression, anxiety, or disconnection that comes with the struggle of being human. 
        </p>
        <p>
        I utilize a flexibly integrative approach to therapy that builds upon your personal strengths to strategically promote health and regeneration of the mind, body, and spirit. As a trauma and PTSD specialist with training in Brainspotting, biofeedback, polyvagal theory, motivational interviewing, and mindfulness behavioral therapy I assist in processing the distressing memories and feelings that may have resulted from your unique life experience. 
        </p>
        <p>
        I am a born and raised indigenous Oregonian of Modoc descent with a deep appreciation for nature and animals. I graduated from The Evergreen State College with a BA in Biopsychology and from Bushnell University with a MA in Clinical Mental Health Counseling.
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
