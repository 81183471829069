import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        Having a safe place to work through what life throws at you can be valuable, and having a counselor who is a good fit can help you feel more at ease in the counseling experience. 
        </p>
        <p>
        I work with people who are experiencing relationship difficulties, going through life transitions, dealing with depression, anxiety, and grief. I use a variety of approaches including Cognitive Behavioral Therapy (CBT), and Dialectical Behavioral Therapy (DBT), and Individual Emotionally Focused Therapy (IFIT). I am grounded in an attachment perspective, and I believe that how we see ourselves and relate to others is foundational to our emotional and mental health. I believe individuals can experience genuine change and growth when they have the support they need, even when they feel the situation is “impossible”. In fact, sometimes that is the catalyst that allows individuals to break free from longstanding struggles, or to face the fears that prevent them from moving forward.
        </p>
        <p>
        I have been working with people’s emotional well-being over the past twenty years and have been in the professional counseling role since 2020. Everyone comes into therapy with different needs; some need to learn skills, some need support to make the changes life demands, and others need to process painful emotions. I would be honored to support you wherever you are in your journey; you don’t have to do this alone.
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
