import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
            Leslie Hyland, Employee Relations, at Oregon Directions, holds a Master's Degree in Special Education. She has recently retired from 34 years of teaching students K-12 in the classroom setting, gifted and talented students and preschool children with disabilities.  Leslie feels that her special talent is effectively collaborating with parents working as a team to best support students in their learning journey.
        </p>
        <p>
            She brings over 30 years of experience working with students, parents and administration to her present job, helping and coordinating clients, counselors and administrative staff at Oregon Directions to work together for effective and efficient office operations. Her new role as Operations Coordinator, which is a multi-faceted position, she tracks office expenses, supports office staff and overseas office operations.
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
