import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import FadeInOnScroll from "../components/FadeInOnScroll";
import CallToAction from "../components/CallToAction";
import IntroBand from "../components/IntroBand";
import counselorsData from "../data/placeholder-counselor-data.json";
import SidebarFilter from "../components/SidebarFilter";
import ButtonPlain from "../components/ButtonPlain";
import ButtonIcon from "../components/ButtonIcon";
import FilterBoxes from "../components/FilterBoxes";
import BioPopup from "../components/BioPopup";
import BioPerson from "../components/BioPerson";
import SidebarSort from "../components/SidebarSort";

const Hero = styled.div`
  #hero {
    padding: 130px 30px 120px;
    position: relative;
  }

  @media screen and (max-width: 970px) {
    #hero {
      padding: 50px 30px 0;
      margin-bottom: -20px;
      position: relative;
    }
  }

  #hero #hero-graphic {
    position: absolute;
    right: 143px;
    bottom: -35px;
    width: 447px;
  }

  @media screen and (max-width: 1200px) {
    #hero #hero-graphic {
      right: 43px;
    }
  }

  @media screen and (max-width: 970px) {
    #hero #hero-graphic {
      position: relative;
      right: unset;
      bottom: unset;
      max-width: 400px;
      width: 90%;
      display: block;
      margin: auto;
    }
  }

  #hero .wrap {
    width: 1184px;
    margin: auto;
    position: relative;
    z-index: 2;
  }

  #hero .inner-wrap {
    flex-direction: column;
    display: flex;
    width: 474px;
  }

  @media screen and (max-width: 970px) {
    #hero .inner-wrap {
      align-items: center;
      text-align: center;
      margin: 0 auto 20px;
    }
  }

  #hero .inner-wrap p {
    margin: 30px 0;
  }

  @media screen and (max-width: 1050px) {
    #hero .inner-wrap p {
      width: 400px;
      max-width: 100%;
    }
  }
`;

const ListingSection = styled.section``;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1104px;
  padding: 0 30px;
  box-sizing: content-box;
  margin: 50px auto;
  @media screen and (max-width: 1050px) {
    flex-direction: column;
  }
  .left {
    display: flex;
  }
  button {
    margin-right: 10px;
  }
`;

const ClearFilterItems = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 1050px) {
    flex-direction: column;
    margin-top: 20px;
    width: 400px !important;
    max-width: unset;
  }
`;

const ClearAllButton = styled.div`
  text-align: center;
  font-family: Raleway;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.27;
  text-decoration-line: underline;
  cursor: pointer;
  color: ${({ active }) => (active ? "black" : "rgba(47, 54, 48, 0.4)")};
  padding-left: 20px;
  @media screen and (max-width: 1050px) {
    padding-left: 0;
  }
`;

const LoadMoreButton = styled.button`
  padding: 10px 20px;
  margin: 20px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #555;
  }
`;

const CounselorsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
`;

const ProfileCard = styled.div`
  width: 300px;
  text-align: center;
  margin: 30px 40px;

  @media screen and (max-width: 850px) {
    margin: 10px 20px;
  }

  //hover state
  &.listing-card > div {
    cursor: pointer;
  }
  &.listing-card > div:hover {
    scale: 1.02;
  }
  &.listing-card > div img:hover {
    scale: 1.05;
    filter: grayscale(0);
  }
`;

const ProfileImageWrap = styled.div`
  width: 300px;
  height: 300px;
  border-radius: 10px;
  transition: scale 0.3s ease;
  overflow: hidden;
`;

const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  transition: filter 0.3s ease, scale 0.3s ease;
  scale: 1;
  object-fit: cover;
  object-position: 0 ${(props) => (props.profilePosition ? `${props.profilePosition}` : '0')};
`;

const Name = styled.div`
  font-size: 22px;
  line-height: 1.27;
  font-weight: bold;
  margin-top: 10px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.27;
`;

const Location = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.27;
`;

const Languages = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.27;
`;

const Tagline = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.27;
`;

const CenterWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const BioContent = styled.div`
  display: flex;
  align-items: flex-start;
  max-height: 1021px;

  @media screen and (max-height: 1100px) {
    max-height: 80vh;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    max-height: unset;
    /* overflow: auto; */
  }
`;

const BioRight = styled.div`
  max-width: 744px;
  padding-top: 40px;
  position: relative;

  > div {
    padding: 0 20px;
    overflow: auto;
    height: 787px;
    padding-bottom: 100px;

    @media screen and (max-height: 1100px) {
      max-height: 65vh;
    }
    @media screen and (max-width: 800px) {
      height: unset;
      overflow: unset;
    }
  }
  .fade-block {
    position: absolute;
    pointer-events: none;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  h2 {
    color: black;
    margin: 40px 0 20px;
    font-size: 18px;
    font-weight: 700;
  }
  h2:first-of-type {
    margin-top: 0;
  }

  p,
  li {
    font-size: 18px;
    line-height: 1.4;
    margin: 20px 0;
  }
  li {
    margin-left: 30px;
    list-style: disc;
  }
`;

const IntroBandAuthor = styled.div`
font-family: Raleway;
font-size: 18px;
font-weight: 400;
line-height: 26px;
text-align: center;
margin-top: 15px;
`;

const filterCounselorsData = (counselorsData, filters) => {
  return counselorsData.filter((counselor) => {
    return Object.entries(filters).every(([filterName, filterValues]) => {
      if (!filterValues.length) return true; // No filter selected for this category
      if (Array.isArray(counselor[filterName])) {
        // If the property is an array, check for intersection
        return counselor[filterName].some((value) =>
          filterValues.includes(value)
        );
      } else {
        // Otherwise, check if the property matches one of the filter values
        return filterValues.includes(counselor[filterName]);
      }
    });
  });
};

function Home() {
  //popup
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedCounselor, setSelectedCounselor] = useState(false);
  const handleClose = () => {
    setIsPopupVisible(false);
  };

  // Start with 12 counselors, increase by 12 each time load more button is clicked
  const defaultVisibilityCount = 12;
  const [visibleCounselors, setVisibleCounselors] = useState(
    defaultVisibilityCount
  );
  const handleLoadMore = () => {
    setVisibleCounselors(
      (prevVisibleCounselors) => prevVisibleCounselors + defaultVisibilityCount
    );
  };

  //filter side bar
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [filteredCounselors, setFilteredCounselors] = useState(counselorsData);

  // Filter order of options to display in sidebar
  const filterNames = [
    "type",
    "state",
    "languages",
    "location",
    "client_focus",
    "participants",
    "top_specialities",
    "other_expertise",
    "therapies",
    "qualifications",
    "tagline",
  ];

  //create object of arrays from filterNames
  const defaultEmptyFilters = filterNames.reduce(
    (acc, filterName) => ({ ...acc, [filterName]: [] }),
    {}
  );

  const [filters, setFilters] = useState({ ...defaultEmptyFilters });

  //filter button states
  const [filterActive, setFilterActive] = useState(false);

  //filter summary (as a count of currently active)
  const filterCount = Object.values(filters || {})
    .filter((value) => value && value.hasOwnProperty("length"))
    .reduce((count, category) => count + category.length, 0);

  useEffect(() => {
    const fc = filterCounselorsData(counselorsData, filters);
    setFilteredCounselors(fc);
    setVisibleCounselors(defaultVisibilityCount);
  }, [filters]);

  function areAllFiltersEmpty(filters) {
    return Object.keys(filters).every(
      (key) => Array.isArray(filters[key]) && filters[key].length === 0
    );
  }

  //sort side bar
  const [isSortSidebarOpen, setSortSidebarOpen] = useState(false); // Step 2

  const handleSort = (order) => {
    const sorted = [...filteredCounselors].sort((a, b) => {
      const nameA = a.name.toLowerCase(),
        nameB = b.name.toLowerCase();
      if (order === "asc") return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      else return nameA > nameB ? -1 : nameA < nameB ? 1 : 0;
    });
    setFilteredCounselors(sorted);
    setSortSidebarOpen(false); // Automatically close the sidebar after sorting
  };

  const onClearSort = () => {
    // Assuming counselorsData holds the original unsorted list of counselors
    setFilteredCounselors(counselorsData);
    setSortSidebarOpen(false); // Close the sort sidebar
  };

  return (
    <FadeInOnScroll>
      <div className="green-bg">
        <Nav />
      </div>

      <main>
        {isPopupVisible && (
          <BioPopup onClose={handleClose}>{selectedCounselor}</BioPopup>
        )}
        <Hero className="green-bg">
          <section id="hero">
            <div className="wrap">
              <div className="inner-wrap">
                <h1>Our Counselors</h1>
                <p>
                  We are a diverse group of individuals who can help you take the next step in your journey.<br/><br/>Please scroll down, use the filter to search, and click on our images below for more details. 
                </p>
              </div>
            </div>
            <img
              id="hero-graphic"
              className="scroll-fade-in"
              src="images/counselors/balancing-rocks.png"
              alt="balance rocks"
            />
          </section>
        </Hero>
        <IntroBand>
          "Between stimulus and response there is a space. In that space is our power to choose our response. In our response lies our growth and our freedom."
          <IntroBandAuthor>- Viktor E. Frankl</IntroBandAuthor>
        </IntroBand>
        <ListingSection>
          <Controls>
            <div className="left">
              <ButtonIcon
                icon={"sort"}
                onClick={() => setSortSidebarOpen(!isSortSidebarOpen)}>
                Sort
              </ButtonIcon>
              <ButtonIcon
                count={filterCount || undefined}
                icon={"filter"}
                active={!areAllFiltersEmpty(filters)}
                onClick={() => {
                  setSidebarOpen(!isSidebarOpen);
                  setFilterActive(!filterActive);
                }}>
                Filter
              </ButtonIcon>
            </div>
            <SidebarSort
              isOpen={isSortSidebarOpen}
              onClose={() => setSortSidebarOpen(false)}
              onSort={handleSort}
              onClearSort={onClearSort}
            />
            <SidebarFilter
              counselorsData={counselorsData}
              isOpen={isSidebarOpen}
              onClose={() => setSidebarOpen(false)}
              setFilters={setFilters}
              filters={filters}
              defaultEmptyFilters={defaultEmptyFilters}
              filterNames={filterNames}
            />
            {filterCount > 0 && (
              <ClearFilterItems>
                {filters && (
                  <FilterBoxes filters={filters} setFilters={setFilters} />
                )}

                <ClearAllButton
                  active={!areAllFiltersEmpty(filters)}
                  onClick={() => setFilters(defaultEmptyFilters)}>
                  Clear all
                </ClearAllButton>
              </ClearFilterItems>
            )}
          </Controls>
          <CounselorsSection>
            {filteredCounselors
              .slice(0, visibleCounselors)
              .map((counselor, index) => {

                const counselorTitle = Array.isArray(counselor.qualifications) ? counselor.qualifications[0]+', '+counselor.qualifications[1] : counselor.qualifications; 
                const counselorLanguages = Array.isArray(counselor.languages) ? counselor.languages[0]+', '+counselor.languages[1] : counselor.languages; 

                const CardContent = () => (
                  <>
                    <Name>{counselor.name}</Name>
                    <Title>{counselorTitle}</Title>
                    <ProfileImageWrap>
                      <ProfileImage
                        src={`/images/counselors/profile-placeholders/${counselor.profile}`}
                        alt={counselor.name}
                        profilePosition={counselor.profilePosition}
                      />
                    </ProfileImageWrap>
                    <Languages>Languages: {counselorLanguages}</Languages>
                    <Location>Location: {counselor.location}</Location>
                    <Tagline>{counselor.tagline}</Tagline>
                  </>
                );

                const PopupContent = () => (
                  <BioContent>
                    <ProfileCard
                      key={index}
                      onClick={() => setIsPopupVisible(true)}>
                      <CardContent />
                    </ProfileCard>
                    <BioRight>
                      <BioPerson  personName={counselor["bio-counselor"]}/>
                      <div className="fade-block"></div>
                    </BioRight>
                  </BioContent>
                );

                const profileCard = (
                  <ProfileCard
                    className={"listing-card"}
                    key={index}
                    onClick={() => {
                      setSelectedCounselor(PopupContent);
                      setIsPopupVisible(true);
                    }}>
                    <CardContent />
                  </ProfileCard>
                );

                return profileCard;
              })}
            {filteredCounselors.length === 0 && (
              <CenterWrap>
                <h2>No counselors found</h2>
              </CenterWrap>
            )}
          </CounselorsSection>
          <div className="gap-sm"></div>
          {visibleCounselors < filteredCounselors.length && (
            <CenterWrap>
              <ButtonPlain onClick={handleLoadMore} bold>
                Load More
              </ButtonPlain>
            </CenterWrap>
          )}
          <div className="gap"></div>
        </ListingSection>
        <CallToAction />
      </main>
      <Footer />
    </FadeInOnScroll>
  );
}

export default Home;
