import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
        <h2>Introduction</h2>
        <p>
        The only constant in life is change. Although change can be daunting, I believe people have an
innate ability to transform their lives. As your counselor, I aim to empower you by discovering
and building upon your strengths, developing resilience, and awakening the courage that
already exists within you.
<p>
  
</p>
Our work together will be a collaboration where you determine the pace and direction for your
growth and healing. Recognizing the unique qualities and experiences that shape each
individual, I tailor my approach to fit your needs, ensuring you feel supported throughout your
journey of self-discovery.
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
