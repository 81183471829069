import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        Do you ever feel stuck in life? It's like the world is spinning around you but YOUR world is standing still? I'll be honest...change is hard! Life transitions, like divorce, new jobs, a move, etc, can take it's toll...especially if you don't have the support and tools to cope with moving forward. This is what I offer - authentic support that meets your needs in a way that allows you to dream again and step into who you were meant to be. Together, we will get to the root, bring healing and lasting change.
        </p>
        <p>
        I approach supervision from a developmental perspective working with the therapist in a style that matches their skill level and experience. I provide ongoing evaluation and supportive challenge to the therapist’s increasing mastery of therapeutic skills, self-awareness and ability to meet their clients in a way that is most beneficial for their sessions. The supervisory relationship will adjust to meet the needs of the supervisee as their therapeutic skills and growth advance in the relationship.
        </p>
        <p>
        Live observation, audio or video tapes, case or process notes are all utilized in the supervisory process. I will assign homework (e.g., visual journaling) to further the supervision process and to enhance and develop the importance of self-awareness within any therapeutic relationship.
        </p>
        <p>
        Evaluation will be an ongoing part of supervision that will take place informally in every session and formally every quarter or if in school, whenever the formal evaluations are due.
        </p>
        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
