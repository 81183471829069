import React from 'react';
import { useProgress } from "../../contexts/ProgressContext";
import styled from "styled-components";
import ButtonPlain from "../ButtonPlain";
import IntakeQuestionnaire from "./IntakeQuestionnaire"; // Replace with the actual next step component

const OutOfPocketDetailsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media screen and (max-width: 700px) {
    padding-bottom: 50px;
  }

  @media screen and (max-height: 780px) and (min-width: 700px) {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  h2 {
    margin-bottom: 20px;
  }

  .price {
    color: #5e8a64;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    margin: 20px auto;
  }

  .information {
    width: 716px;
    max-width: 100%;
    margin: 20px auto 40px;
  }

  button {
    margin-top: 20px;
    width: 240px;
  }
`;

const OutOfPocketDetailsUtah = () => {
  const { nextStep } = useProgress();

  const continueClicked = () => {
    nextStep(<IntakeQuestionnaire />, 90);
  };

  return (
    <OutOfPocketDetailsWrap>
      <h2>Out of pocket</h2>
      <p className="price">We accept $150 per hour cash or credit card.</p>
      <p className="information">
        Cash clients please pay your counselor at the beginning of the session.<br></br><br></br> Credit card clients please add your credit card to Simple Practice before your first session. You will automatically be charged each session.
      </p>
      <ButtonPlain className="continue-button" dark onClick={continueClicked}>
        Continue
      </ButtonPlain>
    </OutOfPocketDetailsWrap>
  );
};

export default OutOfPocketDetailsUtah;
