import React from "react";
import styled from "styled-components";

const EmergencyYesWrap = styled.div`
  text-align: center;

  h2 {
    margin-bottom: 20px;
  }

  .yes-emphasis {
    color: #5e8a64;
    text-align: center;
    font-size: 62px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  p {
    margin-top: 20px;
    line-height: 1.6;
    width: 833px;
    max-width: 100%;
  }

  .emergency-info {
    font-weight: bold;
  }
`;

const EmergencyYes = () => {
  return (
    <EmergencyYesWrap>
      <h2>Is this an emergency?</h2>
      <div className="yes-emphasis">Yes</div>
      <p>
        We do not provide crisis intervention services.<br></br><br></br>If you are
        experiencing a medical, mental health, or substance use crisis, do not
        contact your counselor or our office.&nbsp;
        <span className="emergency-info">
          Please call 911, your county crisis line, or go directly to your
          nearest emergency room.
        </span>
      </p>
    </EmergencyYesWrap>
  );
};

export default EmergencyYes;
