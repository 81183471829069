import React, { createContext, useState, useContext, useEffect } from "react";
import IsEmergencyStep from "../components/getStartedSteps/IsEmergencyStep.js";

const ProgressContext = createContext();

export const useProgress = () => useContext(ProgressContext);

export const ProgressProvider = ({ children }) => {
  const [percent, setPercent] = useState(0);
  const [content, setContent] = useState(null);

  const [history, setHistory] = useState([]);

  const [location, setLocation] = useState("");
  const [payingBy, setPayingBy] = useState("");

  const addHistory = (newContent, newPercent) => {
    // Create a new history array without mutating the original state
    const newHistory = [
      ...history,
      { content: newContent, percent: newPercent },
    ];
    setHistory(newHistory);
  };

  const nextStep = (newContent, newPercent) => {
    setContent(newContent);
    setPercent(newPercent);
    addHistory(newContent, newPercent);
  };

  const prevStep = () => {
    // Remove the last item from the history and update the state
    const newHistory = [...history];
    newHistory.pop();
    setHistory(newHistory);

    if (newHistory.length > 0) {
      // Set the content and percent to the last item in the new history
      const lastStep = newHistory[newHistory.length - 1];
      setContent(lastStep.content);
      setPercent(lastStep.percent);
    }
  };

  useEffect(() => {
    const initialStartingPercentage = 5;
    const initialStartingStep = <IsEmergencyStep />;
    nextStep(initialStartingStep, initialStartingPercentage);
  }, []);

  return (
    <ProgressContext.Provider
      value={{
        percent,
        content,
        history,
        nextStep,
        prevStep,
        location,
        setLocation,
        payingBy,
        setPayingBy,
      }}
    >
      {children}
    </ProgressContext.Provider>
  );
};
