import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
        <h2>Introduction</h2>
        <p>
Welcome. I am a native Oregonian. I was born in Lane County and have lived here most of my life. In my youth, I worked with my family filling orders for firewood. Through this, I learned about the importance of safety and trusting others. After moving to Eugene for high school, I got a job as a dishwasher where I was under pressure and needed to develop an eye for detail. After high school, I worked in production and logistics, which furthered my skills. I started working in mental health 10 years ago. I have experience working with individuals from several backgrounds including dual diagnoses, Acquired and Traumatic brain injuries, Intellectual and developmental disabilities, Psychosis, borderline, LGBTQIA2S+, and Autism. I have worked with a range of ages from elementary to senior citizens.
    </p>
    <p>
    I am rooted in a person-centered approach. I draw from many other types of therapeutic theory including mindfulness, cognitive behavioral therapy, dialectical behavioral therapy, acceptance and commitment therapy, and others. I believe in each person's strengths and the communal support systems they build around them. Growth is not easy and takes a commitment. Healing can and will at times be difficult. Through our work, we can find empowerment. Each person is unique and each path is their own. Work with me through each step as we collaborate to find your path.
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
