import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        I am a person-centered therapist committed to creating a safe space where I can empower clients to find fulfillment in themselves and their lives. I believe that we are beautifully unique, while recognizing that our life experiences can leave us with internalized beliefs, messages, or patterns we hope to address in order to live a more content life. I work collaboratively with clients to develop greater self-awareness while holding space for healing and reclamation of strength.
        </p>
        <p>
        I believe we all have within us the ability to grow and heal, and my role as a therapist is to support clients on their own path through exploration, guidance, and skill development to help them create the changes they wish to see in their life.
        </p>
        <p>
        Therapy is a very personal and individual experience, and I tailor my practice to the needs and desires of my clients, incorporating aspects of Cognitive Behavioral Therapy (CBT), Dialectical Behavioral Therapy, Internal Family Systems (IFS), Person Centered Therapy, Solutions Focused Therapy, and mindfulness techniques. I primarily work with individuals dealing with anxiety, depression, grief, and life transitions, but have experience working with diverse populations from all walks of life. 
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
