import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        Amidst the individual and collective suffering in our world, to begin this journey towards wellness and healing can feel challenging, but can also offer connection and hope. I strive to create an accepting and supportive space for you to feel truly seen and heard, utilizing a person-centered and strengths-based approach in accompanying you on this journey. I seek to bring compassion, warmth, and unconditional positive regard to our sessions. It is important to me that we incorporate approaches which honor your unique needs, and that we do so in an affirming and gentle manner. I work to create a welcoming space for all individuals, and am committed to ongoing reflection and education in order to provide care from a place of cultural humility while acknowledging my own personal privilege. I hope that this can be a space in which you feel supported, celebrated, and cared for.

        </p>
        <p>
        Through our work together, I honor you as the expert of your own life and utilize a variety of perspectives and techniques along the way. I incorporate cognitive behavioral therapy, solution-focused approaches, compassion focused therapy, mindfulness-based approaches, and elements of dialectical behavior therapy, amongst others. I have completed level 1 Brainspotting training through the Pacific Counseling and Trauma Center and I am currently enrolled in Lewis and Clark’s eating disorder counseling certificate program. I typically work with clients age sixteen and up who may be interested in exploring some of the following areas: body image, patterns of disordered eating, self-esteem, self-compassion, self-advocacy, identity, existential struggles, anxiety, depression, grief and loss, and motivation/routine. When addressing body image and food and eating concerns, I operate from a HAES (Health At Every Size) and body-acceptance framework.

        </p>
        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
