import styled from "styled-components";

const ButtonWrap = styled.button`
  font-size: 22px;
  border: ${({ active }) =>
    active ? "2px solid #5E8A64" : "2px solid #2F3630"};
  background: none;
  color: ${({ active }) => (active ? "#5E8A64" : "#2F3630")};
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s, color 0.3s;
  font-weight: ${({ bold }) => (bold ? "bold" : "500")};
  width: 187px;
  &:hover {
    opacity: 0.7;
  }

  font-weight: bold;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  padding: 10px 20px;
  justify-items: center;
  align-items: center;
  height: 50px;

  .text {
    justify-self: baseline;
  }

  &.three-col {
    grid-template-columns: 1fr 2fr 1fr;
    .text {
      justify-self: center;
    }
  }

  .count {
    display: flex;
    align-items: center;
    font-size: 18px;
    position: relative;
    top: -2px;
  }
`;

const ButtonIcon = ({ icon, children, active, onClick, count }) => {
  let src = `/images/icons/${icon}.svg`;
  if (active) {
    src = `/images/icons/${icon}-active.svg`;
  }

  return (
    <ButtonWrap
      onClick={onClick}
      active={active}
      className={count ? "three-col" : ""}>
      <img src={src} alt="icon" />
      <div className="text">{children}</div>
      {count && <div className="count">{count}</div>}
    </ButtonWrap>
  );
};

export default ButtonIcon;
