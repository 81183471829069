import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
        <h2>Introduction</h2>
        <p>
        Nick is a Licensed Professional Counselor and has been counseling in Oregon for the past eight years. His primary modalities include Cognitive Behavioral Therapy, Acceptance and Commitment Therapy, Dialectical Behavior Therapy, Motivational Interviewing, Internal Family Systems and EMDR, while also incorporating somatic and mindfulness practices. His strengths include an approachable, nonjudgmental attitude and a genuine curiosity and enthusiasm for the client's own internal process for making change. He has a cat named Kabuki and spends his free time doing theatre and practicing aikido. He is excited to meet you and begin walking with you on your mental health journey.
        </p>
        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
