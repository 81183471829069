import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        The first step can sometimes feel like the hardest and reaching out for help is a tough hill to climb. I am honored to walk alongside people as they take steps along the courageous journey towards healing through therapy. I believe in empowering my client's to recognize their strengths and work to push them towards becoming the best versions of themselves. 
        </p>
        <p>
        I seek to create a safe space for client's to feel accepted and understood. My experience in various clinical settings has allowed me the honor to work with people from all walks of life and to learn from them. I seek to continually educate myself on the impact I have on society as an educated white woman. I am a lifelong learner who wants to find new ways to use my power and privilege to advocate for others and fight for justice on behalf of those marginalized by society.
        </p>
        <p>
        I work with individuals, couples, and families. I bring specialized experience in working with children and teenagers from various community based mental health roles. I use a Person-Centered therapeutic approach and incorporate interventions from Trust-Based Relational Intervention (TBRI), Cognitive Behavior Therapy (CBT), Dialectical Behavioral Therapy (DBT), Solution Focused Therapy, Mindfulness Therapy, Emotionally Focused Therapy (EFT), Play Therapy, and Art Therapy.
        </p>
        <p>
        I have worked with people who have experienced poverty, houselessness/housing insecurity, trauma, anxiety, depression, PTSD, mood disorders, ADHD, Autism, and various other developmental/ intellectual disabilities.
        </p>
        <p>
        I strongly believe that we are not only the product of our experiences in life and that we have the power to positively impact the trajectory of our lives. My goal is to support my clients with seeing their potential and support them in finding deep healing.
        </p>
        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
